import React, { Component } from "react";

import Hero from "../components/home/Hero3";
import SEO from "../components/seo";



// if (typeof window !== `undefined`) window.location.replace(`https://celerx.app/`) 


export default class IndexPage extends Component {
  
  render() {
   
    
    return (
  // <Layout>
  <>
    <SEO title="Home" />
    <Hero />
  </>
    // {/* <Onboard /> */}
  // {/* </Layout> */}
    );
  }
}
