import React from "react";
import Section from "../global/Section";
import Newsletter from "../global/Newsletter"
import logo from "../../images/logo/logo.png"



export default function Hero() {


  return (
    <Section
      secStyle={"sec-backgroundImg"}
    >
      <div className="row justify-content-center py-3">
        <div className="col-sm-12 col-md-8 col-lg-6 mt-5">
          <img alt="logo" src={logo} style={{width:"100%", display:"block", margin:"auto", marginTop:"160px"}}/>
        </div>
      </div>
      
      <div className="row justify-content-center py-3">
      
       <div className="col-sm-12 col-md-8 col-lg-6">
          <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-5">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                    <a href="https://blog.celer.network/2021/04/22/the-layer2-finance-v0-1-mainnet-launches-democratize-defi-simple-and-zero-fees/" className="btn" target="_blank"  rel="noreferrer" style={{border:"2px solid #CA9979", display:"block", margin:"0, auto"}}>
                <p className="font-w-bold text-center my-1" style={{color:"#CA9979"}}>Read the launch blog</p>
                  </a>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                  <a href="https://app.l2.finance" className="btn" target="_blank" rel="noreferrer" style={{background:"#CA9979", display:"block", margin:"0, auto"}}>
                <p className="font-w-bold text-center my-1" style={{color:"white" }}>Use App (optimistic rollup version)</p> </a>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                  <a href="https://zk.layer2.finance" className="btn" target="_blank" rel="noreferrer" style={{background:"#CA9979", display:"block", margin:"0, auto"}}>
                <p className="font-w-bold text-center my-1" style={{color:"white" }}>Use App (zk-proof version)</p> </a>
                </div>

                </div>
              
                <div className="my-4">
                  <Newsletter/>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="row text-center content-medium-title my-auto" style={{textDecoration:"underline", whiteSpace:"nowrap", overflow:"hidden", color:"#CA9979"}}>
                  <div className="px-4 col-sm-12 col-md-3 col-lg-3">
                    <a href="https://blog.celer.network/" style={{color:"#CA9979"}}>Blog</a>
                  </div>
                 
                  <div className="px-4 col-sm-12 col-md-3 col-lg-3">
                    <a href="https://twitter.com/layer2finance" style={{color:"#CA9979"}}>Twitter</a>
                  </div>
                  
                  <div className="px-4 col-sm-12 col-md-3 col-lg-3">
                    <a href="https://discord.gg/Trhab5w" style={{color:"#CA9979"}}>Discord</a>
                  </div>
                  <div className="px-4 col-sm-12 col-md-3 col-lg-3">
                    <a href="https://www.celer.network/" style={{color:"#CA9979"}}>Celer</a>
                  </div>
                </div>
              </div>
          </div>
          </div>
      </div>
    </Section>   
  );
}
